'use strict';

var processInclude = require('base/util');

/**
 * Init PD Slider
 */
function pdSlider() {

    if ($('.pd-slider.slick-initialized').length) {
        $('.pd-slider').slick('unslick');
    }

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $(".experience-page").addClass('in-editor');

        $('.experience-component .carousel').each(function() {
            if(!$(this).hasClass('edit-mode')){
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.experience-component .carousel').each(function() {
            $(this).find('.pd-slider').slick();
        });
    }
}

/**
 * Lighten or Darken Color
 *
 * The CSS preprocessors Sass and LESS can take any color and darken() or
 * lighten() it by a specific value. But no such ability is built into
 * JavaScript. This function takes colors in hex format (i.e. #F06D06, with or
 * without hash) and lightens or darkens them with a value.
 *
 * @param {String} colorCode The hex color code (with or without # prefix).
 * @param {Int} amount
 */

function LightenDarkenColor(colorCode, amount) {
    var usePound = false;

    if (colorCode[0] == '#') {
        colorCode = colorCode.slice(1);
        usePound = true;
    }
 
    var num = parseInt(colorCode, 16);
 
    var r = (num >> 16) + amount;
 
    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }
 
    var b = ((num >> 8) & 0x00FF) + amount;
 
    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }
 
    var g = (num & 0x0000FF) + amount;
 
    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }
 
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

/**
 * Get Custom colors for buttons
 */
function getCustomColors () {
    let customBtn = document.querySelectorAll('.btn-custom');
    if (customBtn.length) {
        for (let b = 0; b < customBtn.length; b++) {
            customBtn[b].style.setProperty('--bgColor', customBtn[b].dataset.bgcolor);
            customBtn[b].style.setProperty('--bgColor2', LightenDarkenColor(customBtn[b].dataset.bgcolor, -33));
            customBtn[b].style.setProperty('--textColor', customBtn[b].dataset.textcolor);
        }
    }
}

$(document).ready(function () {
    // processInclude(require('./experience/imageAndText'));
    // processInclude(require('./experience/featureFocus'));
    // processInclude(require('./experience/videoTile'));
    processInclude(require('./experience/spacer'));
    // Include Instagram client JS
    processInclude(require('core/components/instagram'));

    $('.pd-text-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function () {
            setTimeout(() => { $('.pd-text-slider .slick-slide').removeAttr('role'); }, 10);
        }
    });

    // Detect if page is in the editor
    if (window.location !== window.parent.location) {
        $('.experience-page').addClass('in-editor');

        $('.experience-component .carousel').each(function (index) {
            if (!$(this).hasClass('edit-mode')) {
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.pd-slider').slick();
    }

    pdSlider();
    getCustomColors();

    if ($('.js-sidebar-links').length > 0) {
        var sidebarLinks = $('.js-blogDetail[data-blogsidebarlink]');

        sidebarLinks = sidebarLinks.filter(function() {
            var linkText = $(this).data('blogsidebarlink');
            return linkText.trim() !== '';
        });

        if (sidebarLinks.length > 1) {
            sidebarLinks.each(function() {
                var linkText = $(this).data('blogsidebarlink');
                var linkId = $(this).data('blogsidebarid');

                var link = $('<a>').attr('href', '#').attr('id', linkId).attr('class', 'anchor-link').text(linkText);
                var listItem = $('<li>').append(link);
                $('.js-sidebar-links ul').append(listItem);
            });
        } else {
            $('.main-article-links').hide();
        }
    }

    $('.js-sidebar-links ul').on('click', 'a', function(event) {
        event.preventDefault();

        var targetId = $(this).attr('id');
        var targetSection = $('.js-blogDetail[data-blogsidebarid="' + targetId + '"]');

        if (targetSection.length > 0) {
            var offsetTop = targetSection.offset().top - 130;

            $('html, body').animate({
                scrollTop: offsetTop
            }, 500);
        }
    });

    $('select.js-blogPost-pagination').on('change', function() {
        var selectedUrl = $(this).val();
        if (selectedUrl) {
            window.location.href = selectedUrl;
        }
    });
});

$(window).smartresize(function() {
    pdSlider();
});

function addClassOnScroll() {
    var windowTop = $(window).scrollTop();
    var elementTop = $('.main-blog-detail').offset().top  - 128;
    var articleLinks = $('.main-article-links');
    var blogDetail = $('.main-blog-detail');
    var endPos = blogDetail.offset().top + blogDetail.height() - articleLinks.height() - 250;

    if (windowTop >= elementTop && $(window).scrollTop() < endPos) {
        $('.js-sidebar-links').addClass('fixed');
    } else {
        $('.js-sidebar-links').removeClass('fixed');
    }
}

addClassOnScroll();

$(window).scroll(function() {
    addClassOnScroll();
});

$(window).resize(function () {
    endPos = blogDetail.offset().top + blogDetail.height() - articleLinks.height() - 250;
});
